import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense, useState } from "react";
import {
  Register,
  NotFound,
  Login,
  IdentityVerification,
  Marketplace,
  Home,
  ForgotPassword,
  ResetPassword,
  EditAccount,
  MyAccount,
  PropertyDetails,
  Dashboard,
  SharedLayout,
  Single,
  NewUser,
  PrivacyPolicy,
  TermsCondition,
  Team,
  Job,
  VerifyEmail,
  Cart,
  Checkout,
  SellToken,
  Order,
  OrderDetails,
} from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./components/Loading";
import i18n from "./i18n";
import LocaleContext from "./LocaleContext";
import Protected from "./components/Protected";
import ProtectedAdmin from "./components/ProtectedAdmin";
import NewTeam from "./pages/new/NewTeam";
import NewLearn from "./pages/new/NewLearn";
import Portfolio from "./pages/Portfolio";
import NewBlogs from "./pages/new/NewBlogs";
import Blog from "./components/Blog";
import Learn from "./components/Learn";
import BlogsDetail from "./components/BlogsDetail";
import NewFAQ from "./pages/new/NewFAQ";
import NewFee from "./pages/new/NewFee";
import NewEmail from "./pages/new/NewEmail";
import NewSocial from "./pages/new/NewSocial";
import BlockChainTabs from "./pages/Faq/BlockChainTabs/BlockChainTabs";
import Tokenized from "./pages/Faq/BlockChainTabs/Right_Components/Tokenized";
import InvenTory from "./pages/Faq/BlockChainTabs/Right_Components/InvenTory";
import ResiDential from "./pages/Faq/BlockChainTabs/Right_Components/ResiDential";
import DeedHouse from "./pages/Faq/BlockChainTabs/Right_Components/DeedHouse";
import LlcDeed from "./pages/Faq/BlockChainTabs/Right_Components/LlcDeed";
import ManageProperty from "./pages/Faq/BlockChainTabs/Right_Components/ManageProperty";
import BlockChain from "./pages/Faq/BlockChainTabs/Right_Components/BlockChain";
import TokenAndCrypto from "./pages/Faq/BlockChainTabs/Right_Components/TokenAndCrypto";
import Tokenization from "./pages/Faq/BlockChainTabs/Right_Components/Tokenization";
import InvesToken from "./pages/Faq/BlockChainTabs/Right_Components/InvesToken";
import Users from "./components/admin/Users";
import Properties from "./components/admin/Properties";
import Teams from "./components/admin/Team";
import Learns from "./components/admin/Learn";
import Blogs from "./components/admin/Blog";
import Faqs from "./components/admin/Faq";
import Subscribes from "./components/admin/Subscribe";
import Socials from "./components/admin/Social";
import Emails from "./components/admin/Email";
import Fees from "./components/admin/Fee";
import Orders from "./components/admin/Order";
import Rents from "./components/admin/Rent";
import CompletedRent from "./components/admin/CompletedRent";
import Terms from "./components/admin/Terms";
import NewTerm from "./pages/new/NewTerm";
import SellTokenList from "./components/admin/SellTokenList";
import Documents from "./pages/Documents";
import DocumentSignature from "./pages/DocumentSignature";
import RejectedOrders from "./pages/RejectedOrders";
import PendingSignatureDetails from "./pages/PendingSignatureDetails";
import Agreements from "./components/admin/Agreement";
// import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import AddProperty from "./pages/new/AddProperty";
import EditBlogs from "./pages/edit/EditBlog";
import EditTeam from "./pages/edit/EditTeam";
import EditLearn from "./pages/edit/EditLearn";
import EditFaq from "./pages/edit/EditFaq";
import EditSocial from "./pages/edit/EditSocial";
import EditProperty from "./pages/edit/EditProperty";
import Rentdeatils from "./components/admin/Rentdeatils";
import ClaimedRentDetails from "./components/admin/ClaimedRentDetails";
import SignaturePad from "./components/SignaturePad"
import PropertyEmail from "./components/admin/PropertyEmail";
import ClaimRent from "./pages/ClaimRent";
import GeneratedRentEmails from "./components/admin/GeneratedRentEmails";
import SellTokenHistory from "./pages/SellTokenHistory";

function App() {
  const [locale, setLocale] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <ToastContainer position="top-right" limit={1} />

          <Routes>
            <Route path="/" element={<SharedLayout />}>
              <Route index element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/signpad" element={<SignaturePad />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/termscondition" element={<TermsCondition />} />
              <Route path="/team" element={<Team />} />
              <Route path="/verifyEmail/:token/" element={<VerifyEmail />} />
              <Route path="/job" element={<Job />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="blog/:blogsId" element={<BlogsDetail />} />
              <Route exact path="/learn" element={<Learn />} />
              <Route path="/faq" exact element={<BlockChainTabs />}>
                <Route exact path="/faq/:faqId" element={<BlockChain />} />

                <Route exact path="" element={<BlockChain />} />
                <Route
                  exact
                  path="tokenAndCrypto"
                  element={<TokenAndCrypto />}
                />
                <Route exact path="tokenization" element={<Tokenization />} />
                {/* <Route path="tokenization" exact element={<TokeNization />} /> */}
                <Route path="tokenized" exact element={<Tokenized />} />
                <Route path="InvesToken" exact element={<InvesToken />} />
                <Route path="inventory" exact element={<InvenTory />} />
                <Route path="residential" exact element={<ResiDential />} />
                <Route path="DeedHouse" exact element={<DeedHouse />} />
                <Route path="llcdeed" exact element={<LlcDeed />} />
                <Route path="mngproperty" exact element={<ManageProperty />} />
              </Route>
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/selltoken" element={<SellToken />} />
              <Route
                path="/idVerification"
                element={
                  <Protected>
                    <IdentityVerification />
                  </Protected>
                }
              />
              <Route
                path="/portfolio"
                element={
                  <Protected>
                    <Portfolio />
                  </Protected>
                }
              />
              <Route
                path="/orderdetails/:id"
                element={
                  <Protected>
                    <OrderDetails />
                  </Protected>
                }
              />
              <Route
                path="/orders"
                element={
                  <Protected>
                    <Order />
                  </Protected>
                }
              />
              <Route
                path="/rejectedOrders"
                element={
                  <Protected>
                    <RejectedOrders />
                  </Protected>
                }
              />
              <Route
                path="/claimRent"
                element={
                  <Protected>
                    <ClaimRent />
                  </Protected>
                }
              />
              <Route
                path="/sellTokenHistory"
                element={
                  <Protected>
                    <SellTokenHistory />
                  </Protected>
                }
              />
              <Route
                path="/documents"
                element={
                  <Protected>
                    <Documents />
                  </Protected>
                }
              />
              <Route
                path="/documentSignature"
                element={
                  <Protected>
                    <DocumentSignature />
                  </Protected>
                }
              />
              <Route
                path="/pendingSignatureDetails/:id"
                element={
                  <Protected>
                    <PendingSignatureDetails />
                  </Protected>
                }
              />

              <Route
                path="/editaccount"
                element={
                  <Protected>
                    <EditAccount />
                  </Protected>
                }
              />
              <Route
                path="/myaccount"
                element={
                  <Protected>
                    <MyAccount />
                  </Protected>
                }
              />
              <Route
                path="/propertydetails/:id"
                element={<PropertyDetails />}
              />
              <Route
                path="/ResetPassword/:userId/:tokenUrl"
                element={<ResetPassword />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="/admin">
              <Route
                index
                element={
                  <ProtectedAdmin>
                    <Dashboard />
                  </ProtectedAdmin>
                }
              />
              <Route path="properties">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Properties />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path=":propertyId"
                  element={
                    <ProtectedAdmin>
                      <EditProperty />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <AddProperty />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              {/* Rent distrbute pending start*/}

              <Route path="rent">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Rents />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              {/* new Route  */}
              <Route path="rentdetails/:id">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Rentdeatils />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              <Route path="claimedrent-details/:id/:ctyptoNetwork">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <ClaimedRentDetails />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              <Route path="generatedRentEmail">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <GeneratedRentEmails />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              <Route path="completedrent">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <CompletedRent />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              <Route path="selltokenlist">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <SellTokenList />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              <Route path="propertyemail">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <PropertyEmail />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/* Rent distrbute completed end */}
              <Route path="team">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Teams />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path=":teamId"
                  element={
                    <ProtectedAdmin>
                      <EditTeam />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <NewTeam />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              <Route path="users">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Users />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ProtectedAdmin>
                      <Single />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path="edit/:id"
                  element={
                    <ProtectedAdmin>
                      <NewUser title="Add New User" />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/*blogs  Routes */}
              <Route path="blogs">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Blogs />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path=":blogsId"
                  element={
                    <ProtectedAdmin>
                      <EditBlogs />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <NewBlogs />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/* Terms and condition */}

              <Route path="terms">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Terms />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ProtectedAdmin>
                      <NewTerm />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              {/* Terms and condition End*/}

              {/* learn Routes */}

              <Route exact path="learn">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Learns />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path=":learnId"
                  element={
                    <ProtectedAdmin>
                      <EditLearn />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <NewLearn />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/* Faq Routes */}
              <Route exact path="faq">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Faqs />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path=":faqId"
                  element={
                    <ProtectedAdmin>
                      <EditFaq />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <NewFAQ />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/* Email Management Routes */}
              <Route exact path="email">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Emails />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path=":emailId"
                  element={
                    <ProtectedAdmin>
                      <NewEmail />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <NewEmail />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/* Fee Management Routes */}
              <Route exact path="fee">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Fees />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path=":feeId"
                  element={
                    <ProtectedAdmin>
                      <NewFee />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <NewFee />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/* Order Routes */}
              <Route exact path="order">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Orders />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path=":orderId"
                  element={
                    <ProtectedAdmin>
                      {/* <NewOrder inputs={OrderInputs} /> */}
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path="new"
                  element={
                    <ProtectedAdmin>
                      {/* <NewOrder inputs={OrderInputs} /> */}
                    </ProtectedAdmin>
                  }
                />
              </Route>
              {/* Order Routes */}

              {/* User Subscribe Management */}

              <Route exact path="subscribe">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Subscribes />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              <Route exact path="agreement">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Agreements />
                    </ProtectedAdmin>
                  }
                />
              </Route>

              {/* User Subscribe Management*/}

              {/* Social Media Management*/}
              <Route exact path="social">
                <Route
                  index
                  element={
                    <ProtectedAdmin>
                      <Socials />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path=":socialId"
                  element={
                    <ProtectedAdmin>
                      <EditSocial />
                    </ProtectedAdmin>
                  }
                />
                <Route
                  exact
                  path="new"
                  element={
                    <ProtectedAdmin>
                      <NewSocial />
                    </ProtectedAdmin>
                  }
                />
              </Route>
              {/* Social Media Management*/}
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </LocaleContext.Provider>
  );
}

export default App;
