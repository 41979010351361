import React, { useEffect, useState } from "react";
import { PortfolioCard } from "../components";
import { toast } from "react-toastify";
import { getOrders } from "../redux/api";
const Portfolio = () => {
  const [property, setProperty] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      getOrders()
        .then((res) => {
          setProperty(res?.data?.orders);
        })
        .catch((error) => {
          return toast.error(error?.response?.data?.message);
        });
    }
  }, []);
  return (
    <>
      <section id="inner-banner" className="bg-gray-700 h-132">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                Your <strong>Portfolio</strong>{" "}
              </h2>
            </div>
            <div className="right-image">
              <img
                src="assets/images/banner2.png"
                alt="banner"
                className="h-full w-full"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto full_Container">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <div className="w-full">
              <div id="tabContentExample">
                <div className="panel rounded overflow-hidden shadow">
                  <div className="panel-heading bg-blue-900 items-center p-5 flex justify-between md:flex-row sm:flex-row flex-col">
                    <h2 className="sm:text-2xl text-xl font-bold text-white sm:mb-0 mb-5 ">
                      Assets
                    </h2>
                    <div className="buttons">
                      <a
                        className="rounded-full px-5 py-2 text-white border-white border-2 sm:mr-2 mr-2"
                        href="/marketplace"
                      >
                        BUY
                      </a>
                      <a
                        className="rounded-full px-5 py-2 text-white border-white border-2 sm:mr-2 mr-2"
                        href="/selltoken"
                      >
                        SELL
                      </a>
                    </div>
                  </div>
                  <div className="panel-body bg-white p-5 panelCard_sec">
                    <div className="flex  w-full flex-col">
                      <div className="2xl:w-full xl:w-full lg:w-full md:w-full  sm:w-full w-full ">
                        {property?.length === 0 && <h1>No property found</h1>}
                        {property &&
                          property?.map((od, i) => (
                            <>
                              {od?.orderDetail.map((product, i) => {
                                return (
                                  <PortfolioCard key={i} product={product} />
                                );
                              })}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
