import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { UserSideBar } from "../components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { me, IdUser } from "../redux/features/authSlice";
import { Spinner } from "../components/";
import { getAllCountry } from "../redux/api";

const initialState = {
  FirstName: "",
  LastName: "",
  Dob: "",
  Email: "",
  BillingCountry: "",
  BillingCity: "",
  BillingStreet: "",
  BillingState: "",
  Phone: "",
  BillingPostalCode: "",
  IdentificationCountry: "",
  PassportNumber: "",
  DrivingLicence: "",
  OtherIdNumber: ""
};

const IdentityVerification = () => {
  const [show, setShow] = useState(false);
  const [driv, setDriv] = useState(false);
  const [other, setOther] = useState(false);

  const [formValue, setFormValue] = useState(initialState);
  const [file, setFile] = useState("");
  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const [phoneCodeList, setphoneCodeList] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    firstName,
    lastName,
    dob,
    email,
    billingCountry,
    billingCity,
    billingStreet,
    billingState,
    phone,
    billingPostalCode,
    identificationCountry,
    comment,
    passportNumber,
    drivingLicence,
    otherIdNumber,
    phoneCode
  } = formValue;

  const { user, loading, error } = useSelector((state) => ({ ...state.auth }));

  useEffect(() => {
    dispatch(me()); 
    getAllCountry().then((res)=>{
      let phoneCodeList = res.data.allCountry.map(({phoneCode, iso3}) => <option value={phoneCode}> {iso3} +{phoneCode} </option>);
      setphoneCodeList(phoneCodeList);
    });

  }, [dispatch]);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    setFormValue({ ...user });
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    dispatch(IdUser({ formData, navigate, toast }));
  };
  const handlePass = () => {
    setShow(true);
    setDriv(false);
    setOther(false);
  };
  const handleDrive = () => {
    setShow(false);
    setDriv(true);
    setOther(false);
  };
  const handleOther = () => {
    setShow(false);
    setDriv(false);
    setOther(true);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <UserSideBar />
            <div id="tabContentExample">
              <div
                className="hidden p-4"
                id="profile-example"
                role="tabpanel"
                aria-labelledby="profile-tab-example"
              ></div>
              <div
                className="hidden p-4"
                id="dashboard-example"
                role="tabpanel"
                aria-labelledby="dashboard-tab-example"
              >
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  This is some placeholder content the{" "}
                  <strong className="font-medium text-gray-800 dark:text-white">
                    Dashboard tab's associated content
                  </strong>
                  . Clicking another tab will toggle the visibility of this one
                  for the next. The tab JavaScript swaps classes to control the
                  content visibility and styling.
                </p>
              </div>
              <div
                className="p-4"
                id="settings-example"
                role="tabpanel"
                aria-labelledby="settings-tab-example"
              >
                <form
                  onSubmit={handleSubmit}
                  method="post"
                  encType="multipart/form-data"
                >
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    Identify
                    <span className="text-blue-700 font-bold">
                      {" "}
                      Verification
                    </span>
                  </h2>
                  <p className="2xl:text-xl text-black dark:text-gray-400 mt-2 mb-0">
                    <span translate="no"> SPACIOS </span> platform, requires verifying your identity. As part
                    of international compliance standards.
                  </p>
                  <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0">
                    Please make sure your verification goes as smoothly as
                    possible by accessing this form from an IP address in your
                    country of residence.
                  </p>
                  <p className="2xl:text-xl text-black dark:text-gray-400 mt-2 mb-12">
                    Thank you!
                  </p>
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    Personal
                    <span className="text-blue-700 font-bold">
                      {" "}
                      information
                    </span>
                  </h2>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="FirstName"
                          name="firstName"
                          onChange={onInputChange}
                          value={firstName || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="LastName"
                          name="lastName"
                          onChange={onInputChange}
                          value={lastName || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Birth Date
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Use month-day-year format
                        </p>
                        <input
                          type="date"
                          id="Dob"
                          name="dob"
                          max={moment().format("YYYY-MM-DD")}
                          onChange={onInputChange}
                          value={dob || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Email
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please enter the valid email
                        </p>
                        <input
                          type="email"
                          id="Email"
                          name="email"
                          onChange={onInputChange}
                          value={email || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Phone
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please include your phone
                        </p>
                        <div style={{ display: "flex" }}>
                          <select name="phoneCode" defaultValue={phoneCode} id="phoneCode" value={phoneCode} onChange={onInputChange} className="py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10" style={{ width: "55%" }}>

                            {phoneCodeList}
                          </select> &nbsp;
                          <input
                            type="text"
                            id="Phone"
                            name="phone"
                            onChange={onInputChange}
                            value={phone || ""}
                            className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    Billing
                    <span className="text-blue-700 font-bold"> Address</span>
                  </h2>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Country
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please enter your billing country
                        </p>
                        <input
                          type="text"
                          id="BillingCountry"
                          name="billingCountry"
                          onChange={onInputChange}
                          value={billingCountry || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Street
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Numbre, street name
                        </p>
                        <input
                          type="text"
                          id="BillingStreet"
                          name="billingStreet"
                          onChange={onInputChange}
                          value={billingStreet || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          City
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Full name of the city{" "}
                        </p>
                        <input
                          type="text"
                          id="BillingCity"
                          name="billingCity"
                          onChange={onInputChange}
                          value={billingCity || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          State/Region
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please selct your region
                        </p>
                        <input
                          type="text"
                          id="BillingState"
                          name="billingState"
                          onChange={onInputChange}
                          value={billingState || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          ZIP/Postal Code
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Your postal code, if you have
                        </p>
                        <input
                          type="text"
                          id="BillingPostalCode"
                          name="billingPostalCode"
                          onChange={onInputChange}
                          value={billingPostalCode || ""}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    <span className="text-blue-700 font-bold">
                      {" "}
                      Identification
                    </span>
                  </h2>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          What country issued your ID?
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please selct the country that issued your ID.
                        </p>
                        <select
                          className="form-select appearance-none
                              block
                              w-full
                              px-3
                              py-1.5
                              text-base
                              font-normal
                              text-gray-700
                              bg-white bg-clip-padding bg-no-repeat
                              mt-3
                              h-10
                              transition
                              ease-in-out
                              m-0
                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          aria-label="Default select example"
                          id="IdentificationCountry"
                          name="identificationCountry"
                          onChange={onInputChange}
                          value={identificationCountry || ""}
                        >
                          <option value="DEFAULT">Open this select menu</option>
                          <option value="Argentina">Argentina</option>
                          <option value="India">India</option>
                          <option value="Chile">Chile</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <h3
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: 24,
                      marginTop: 20,
                    }}
                  >
                    Comment from the admin
                  </h3>
                  {/* <p style={{ color: "maroon", fontWeight: "bold" }}>{userStatus}</p> */}
                  <p style={{ color: "blue", fontWeight: "bold" }}>{comment}</p>
                  <div className="flex mt-12 gap-5 items-start lg:flex-nowrap flex-wrap">
                    <div className="lg:w-1/2 w-full">
                      <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                        <img
                          className="w-72"
                          src={
                            file
                              ? URL.createObjectURL(file)
                              : "assets/images/file-upload.png"
                          }
                          alt=""
                        />
                        <input
                          type="file"
                          className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                          name="image"
                          accept="image/png , image/jpeg, image/webp"
                          // style={{ display: "none" }}
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                      <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0 text-center">
                        Selfie Image
                      </p>
                      <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0 text-center">
                        JPG/PNG
                      </p>
                      <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0 text-center">
                        click to add
                      </p>
                    </div>
                    <div className="lg:w-1/2 w-full border flex-col border-gray-500 px-3 py-5 flex items-start justify-center relative h-auto">
                      {/*Back Doc */}
                      {/* <div className="absolute top-1 left-1.5">
                        <span className="cursor-pointer font-bold underline">Back</span>
                      </div> */}
                      {/*Back Doc */}
                      <h2 className="2xl:text-xl xl:text-xl lg:text-xl md:text-xl sm:text-xl font-semibold text-2xl text-black mt-0 text-center w-full">
                        Please select a form of ID:
                      </h2>
                      <div className="flex justify-center items-center w-full mt-5">
                        <ul className=" w-96 text-gray-900">
                          <li
                            onClick={handlePass}
                            className="font-bold px-6 py-2 border-b border-blue-200 hover:bg-blue-700 cursor-pointer w-full mb-5 bg-blue-800 rounded-full text-center text-gray-200"
                          >
                            Passport
                          </li>
                          <li
                            onClick={handleDrive}
                            className="font-bold px-6 py-2 border-b border-blue-200 hover:bg-blue-700 cursor-pointer w-full mb-5 bg-blue-800 rounded-full text-center text-gray-200"
                          >
                            Driver´s Licence
                          </li>
                          <li
                            onClick={handleOther}
                            className="font-bold  px-6 py-2 border-b border-blue-200 hover:bg-blue-700 cursor-pointer w-full mb-5 bg-blue-800 rounded-full text-center text-gray-200"
                          >
                            Other ID{" "}
                          </li>
                        </ul>
                      </div>
                      <>
                        {/*Passport Doc */}
                        {show ? (
                          <div className="passport-doc upload-doc-item">
                            <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                              <img
                                src={
                                  front
                                    ? URL.createObjectURL(front)
                                    : "assets/images/file-upload.png"
                                }
                                className="w-72"
                                alt=""
                              />
                              <input
                                type="file"
                                className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                name="passport"
                                onChange={(e) => setFront(e.target.files[0])}
                              />
                            </div>
                            <div className="text-center">
                              <h5 className="font-black">
                                PASSPORT (LOWER PORTION)
                              </h5>
                              <span className="italic text:2xl text-center w-100 block">
                                JPG/PNG
                              </span>
                              <p className="text-blue-800 font-regular text-md">
                                Your Passport Number
                              </p>
                              <input
                                type="text"
                                id="PassportNumber"
                                name="passportNumber"
                                onChange={onInputChange}
                                value={passportNumber || ""}
                                className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                              />
                            </div>
                          </div>
                        ) : null}

                        {/*Passport Doc End */}
                        {driv ? (
                          <div className="driver-doc upload-doc-item">
                            <div className="flex">
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      front
                                        ? URL.createObjectURL(front)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="driving_front"
                                    onChange={(e) =>
                                      setFront(e.target.files[0])
                                    }
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">
                                    DRIVER'S LICENSE FRONT
                                  </h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>
                                </div>
                              </div>
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      back
                                        ? URL.createObjectURL(back)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="driving_back"
                                    onChange={(e) => setBack(e.target.files[0])}
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">
                                    DRIVER'S LICENSE BACK
                                  </h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>

                                </div>
                              </div>
                            </div>
                            <p className="text-blue-800 font-regular text-md">
                              YOUR DRIVER'S LICENSE NUMBER
                            </p>
                            <input
                              type="text"
                              id="DrivingLicence"
                              name="drivingLicence"
                              onChange={onInputChange}
                              value={drivingLicence || ""}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        ) : null}
                        {/* Driver Doc */}

                        {/* Other Doc */}
                        {other ? (
                          <div className="other-doc upload-doc-item">
                            <div className="flex">
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      front
                                        ? URL.createObjectURL(front)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="other_front"
                                    onChange={(e) =>
                                      setFront(e.target.files[0])
                                    }
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">ID FRONT</h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>
                                </div>
                              </div>
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      back
                                        ? URL.createObjectURL(back)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="other_back"
                                    onChange={(e) => setBack(e.target.files[0])}
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">ID BACK</h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>
                                </div>
                              </div>
                            </div>
                            <p className="text-blue-800 font-regular text-md">
                              Your Id Number
                            </p>
                            <input
                              type="text"
                              id="OtherIdNumber"
                              name="otherIdNumber"
                              onChange={onInputChange}
                              value={otherIdNumber || ""}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        ) : null}
                        {/* Driver End */}
                      </>
                    </div>
                  </div>

                  {loading ? (
                    <Spinner />
                  ) : (
                    <input
                      type="submit"
                      defaultValue="Submit for Review"
                      className="bg-blue-800 text-white font-bold text-lg hover:bg-blue-500 p-2 mt-12 max-w-xs rounded-full m-auto w-full block"
                    />
                  )}
                </form>
              </div>
              <div
                className="hidden p-4"
                id="contacts-example"
                role="tabpanel"
                aria-labelledby="contacts-tab-example"
              >
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  This is some placeholder content the{" "}
                  <strong className="font-medium text-gray-800 dark:text-white">
                    Contacts tab's associated content
                  </strong>
                  . Clicking another tab will toggle the visibility of this one
                  for the next. The tab JavaScript swaps classes to control the
                  content visibility and styling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default IdentityVerification;
