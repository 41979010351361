module.exports = {
  ME: "me",
  SEND_USER_UPDATE: "users/sendUpdate",
  LOGIN: "users/login",
  USERS: "users",
  FORGET_PASSWORD: "users/forgot-password",
  SUBSCRIBERS: "subscribers",
  PROPERTY: "properties",
  DELETEIMAGES: "deletePropertyImage",
  PROPERTY_BY_STATUS: "propertiesByStatus",
  CHANGE_PASSWORD: "users/changePassword",
  PROPERTY_STATUS: "properties/status",
  RESET_PASSWORD: "users/ResetPassword",
  TEAM: "team",
  LEARN: "learn",
  BLOGS: "blogs",
  FAQ: "faq",
  FAQ_ADMIN: "admin/faq",
  EMAIL: "email",
  FEE: "fee",
  FEES: "getFees",
  CART: "cart",
  ORDER: "order",
  ORDERFETCHBYADMIN: "orderadmin",
  ORDERRENTDISTRIBUTION: "orderrent",
  ORDERRENTCOMPLETED: "orderCompletedRent",
  SOCIAL: "social",
  TERM: "tc",
  VERIFY_EMAIL: "users/verifyMail",
  PROPERTY_SOLD: "properties/sold",
  PROPERTY_DEPLOY: "properties/smart",
  UPDATE_MONTHLY_RENT: "/orderrent",
  CHECKOUTORDER: "order/createCheckoutId",
  PROPERTIESCOUNT: "getPropertiesCount",
  TOTALTOKEN: "PropertiesTotalToken",
  TOTALPRICE: "totalPrice",
  YEARLYGROSSRENT: "yearlyGrossRent",
  MONTHLYGROSSRENT: "monthlyGrossRent",
  TOTALINVESTOR: "totalInvestor",
  YEARLYNETRENT: "yearlyNetRent",
  MONTHLYNETRENT: "monthlyNetRent",
  INSTOCK: "properties/stock",
  GETPROPERTYDATA: "getPropertyData",
  GERUSERPROPERTY: "getUserProperty",
  ACTIVEPROPERTIES: "getActiveProperties",
  INVESTORDETAILS: "totalInvestorDetails",
  UPDATESENDPROPERTYTOKEN: "updateSendPropertyToken",
  GETUSERPROPERTYADDRESS: "propertyAddress",
  UPDATE_ORDER_STATUS: "order/status",
  UPDATE_REJECT_ORDER: "rejectOrder",
  GETUSERPROPERYDETAIL: "getPropertyWithId",
  UPDATESELLTOKEN: "saleToken",
  GETSELLTOKEN: "getSaleData",
  UPDATESELLTOKENSTATUS: "updateSaleTokenStatus",
  UPDATETRANSFERREDUSDC: "updateTransferredUSDC",
  DISTURBEDRENT: "getdistubtedRent",
  CREATEINVOICE: "createInvoice",
  GETRENTDISTRIBUTEDDETAILS: "orderrentdetails",
  GETUSERPENDINGRENTDISTRIBUTION: "pendinguserrent",
  GETUSERPENDINGAGGREMENT: "pendingAggrements",
  CREATECONTRACTSIGN: "signContract",
  CREATEAGREEMENTPDF: "createAgreementPdf",
  UPDATEAGREEMENTPDF: "updateAgreementPdf",
  ORDER_DETAILS: "getOrderDetails",
  GENRATE_PROPERTY_REPORT: "genratePropertyReport",
  GENRATE_ORDER_REPORT: "genrateOrderReport",
  GENRATE_RENT_REPORT: "genrateRentReport",
  GENRATE_SALE_TOKEN_REPORT: "genrateSellTokenReport",
  GENRATE_USERS_REPORT: "genrateUsersReport",
  GET_PROPERTY_EMAIL: "getPropertyEmail",
  GET_ALL_COUNTRY: "getAllCountry",
  UPDATE_ORDER_REFUND_PAYMENT: "refundOrderPayment",
  GET_USER_REJECTED_ORDERS: "getUserRejetedOrders",
  GET_USER_PENDING_RENT: "userPendingRent",
  CLAIM_USER_RENT: "claimUserRent",
  GET_PENDING_RENT: "getPendingRent",
  GET_CLAIMED_RENT: "getClaimedRent",
  GET_CLAIMED_RENT_DETAILS: "getClaimedRentDetails",
  UPDATE_CLAIMED_RENT: "updateClaimedRent",
  GET_GENERATED_RENT_EMAILS: "getGeneratedRentEmails",
  GET_USERS_SELL_TOKENS:"getUserSellTokens",
  GET_MY_FEES:"getMyfee"
};
