import React from "react";
import { Link } from "react-router-dom";


const UserSidebar = () => {
  return (
    <div className="2xl:w-1/5 xl:w-1/5 lg:w-1/3 md:w-2/5 sm:w-full w-full">
      <div className="mb-4 ">
        <ul
          className="flex flex-wrap flex-col  -mb-px text-sm font-medium text-left text-gray-500 dark:text-gray-400"
          id="tabExample"
          role="tablist"
        >
          <li className="mr-2" role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="profile-tab-example"
              type="button"
              role="tab"
              aria-controls="profile-example"
              aria-selected="false"
              to="/myaccount"
            >
              <i className="fas fa-user mr-2"></i>
              My Account
            </Link>
          </li>
          <li className="mr-2" role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="dashboard-tab-example"
              type="button"
              role="tab"
              aria-controls="dashboard-example"
              aria-selected="false"
              to="/editaccount"
            >
              <i className="fa fa-solid fa-pen-alt"> </i> {" "}
              Edit Account
            </Link>
          </li>
          <li className="mr-2" role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="settings-tab-example"
              type="button"
              role="tab"
              aria-controls="settings-example"
              aria-selected="false"
              to="/idVerification"
            >
              <i className="fas fa-user mr-2"></i>
              Identify Verification
            </Link>
          </li>
          <li role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="contacts-tab-example"
              type="button"
              role="tab"
              aria-controls="contacts-example"
              aria-selected="false"
              to="/portfolio"
            >
              <i className="fas fa-basketball-ball" aria-hidden="true" /> {" "}
              Portfolio
            </Link>
          </li>
          <li role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="contacts-tab-example"
              type="button"
              role="tab"
              aria-controls="contacts-example"
              aria-selected="false"
              to="/orders"
            >
              <i className="fa fa-th mr-2" aria-hidden="true" />

              Order
            </Link>
          </li>
          
          <li role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="contacts-tab-example"
              type="button"
              role="tab"
              aria-controls="contacts-example"
              aria-selected="false"
              to="/rejectedOrders"
            >
              <i className="fa fa-regular fa-mask" aria-hidden="true" /> {" "}
              Rejected Orders
            </Link>
          </li>
          <li role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="contacts-tab-example"
              type="button"
              role="tab"
              aria-controls="contacts-example"
              aria-selected="false"
              to="/sellTokenHistory"
            >
              <i className="fab fa-cloudscale" aria-hidden="true" /> {" "}
              Sell Token History
            </Link>
          </li>
          <li role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="contacts-tab-example"
              type="button"
              role="tab"
              aria-controls="contacts-example"
              aria-selected="false"
              to="/claimRent"
            >
              <i className="fab fa-cuttlefish" aria-hidden="true" /> {" "}
              Claim Rent 
            </Link>
          </li>
          <li role="presentation">
            <Link
              className="2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              id="contacts-tab-example"
              type="button"
              role="tab"
              aria-controls="contacts-example"
              aria-selected="false"
              to="/documentSignature"
            >
              <i className="fa fa-sharp fa-solid fa-pen" aria-hidden="true" /> {" "}
              Document Signature
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserSidebar;
