import React, { useState, useEffect } from "react";
import ABI from "../_abi.json";
import { toast } from "react-toastify";
import Web3 from "web3";
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import SimpleHeader from "./SimpleHeader";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "../redux/features/authSlice";
import { fetchCart } from "../redux/features/cartSlice";
import { Button, Menu, MenuItem } from "@material-ui/core";
// import  from '@material-ui/core/Menu';
// import  from '@material-ui/core/MenuItem';
import "./MainHeader.css";
import "../assets/css/responsive.css";

const {
  REACT_APP_WEBSITE_URL,
  REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT,
  REACT_APP_POLYGON_USDC_TOKEN_CONTRACT,
  REACT_APP_ETHEREUM_NETWORK_ID,
  REACT_APP_POLYGON_NETWORK_ID,
  REACT_APP_ETHEREUM_RPC_URL,
  REACT_APP_POLYGON_RPC_URL
} = process.env;

const MainHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sticky, setSticky] = useState("");

  const [navbar, setNavbar] = useState(false);

  const [accountAddress, setAccountAddress] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [blockchainNetwork, setblockchainNetwork] = useState("Ethereum");
  const [networkChainId, setnetworkChainId] = useState("");
  const [isEthereumChain, setisEthereumChain] = useState();
  const [isPolygonChain, setisPolygonChain] = useState();
  const navigate = useNavigate();

  const { user } = useSelector((state) => ({ ...state.auth }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  let userInfo = localStorage.getItem("profile");
  const { cartQuantity } = useSelector((state) => state.cart);



  useEffect(() => {
    if (user && user.role !== 1) {
      dispatch(fetchCart());
    }
  }, [dispatch, user]);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(setLogout());
    localStorage.clear();
    navigate("/login");
  };

  const handleNavbarModal = (e) => {
    setNavbar((navbar) => !navbar);
  };

  const connectMetamsk = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          setAccountAddress(result[0]);
          setIsConnected(true);
          window.walletAddress = result[0];
          localStorage.setItem("accountAddress", result[0]);
          // connectContract();
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
        });
    } else {
      toast.error("Please install Metamask");
    }
  };

  const connectContract = async () => {
    var CONTRACTADDRESS;
    new Web3(Web3.givenProvider || "http://localhost:8545");
    await window.ethereum.request({ method: "eth_chainId" }).then((chain) => {
      if (chain == REACT_APP_POLYGON_NETWORK_ID) {
        setblockchainNetwork("Polygon");
        window.networkChain = "Polygon";
        setnetworkChainId(chain);
        setisPolygonChain(true);
        setisEthereumChain(false);
        CONTRACTADDRESS = REACT_APP_POLYGON_USDC_TOKEN_CONTRACT;
      } else if (chain == REACT_APP_ETHEREUM_NETWORK_ID) {
        setblockchainNetwork("Ethereum");
        window.networkChain = "Ethereum";
        setnetworkChainId(chain);
        setisPolygonChain(false);
        setisEthereumChain(true);
        CONTRACTADDRESS = REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT;
      } else {
        CONTRACTADDRESS = REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT;
        setblockchainNetwork(" ");
        window.networkChain = " ";
        // toast.error("Please select valid crypto network");
      }
    });

    const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
    const TOKENCONTRACT_ADDRESS = CONTRACTADDRESS;
    window.TOKENCONTRACT = new web3.eth.Contract(ABI, TOKENCONTRACT_ADDRESS);
  };

  const ChangeNetwork = async (e) => {
    if (window.ethereum) {
      try {
        var _chainId = e.target.value;
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: _chainId }], // chainId must be in hexadecimal numbers
        });
        // connectContract();
      } catch (error) {
        if (error.code === 4902) {
          if (_chainId == REACT_APP_POLYGON_NETWORK_ID) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: REACT_APP_POLYGON_NETWORK_ID,
                    rpcUrl: REACT_APP_POLYGON_RPC_URL,
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
            }
          } else if (_chainId == REACT_APP_ETHEREUM_NETWORK_ID) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: REACT_APP_ETHEREUM_NETWORK_ID,
                    rpcUrl: REACT_APP_ETHEREUM_RPC_URL,
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
            }
          }
        }
        console.error(error);
      }
    } else {
      toast.error("MetaMask is not installed.");
    }
  };

  const disConnectMetamsk = async () => {
    setAccountAddress("");
    localStorage.setItem("accountAddress", "null");
    setIsConnected(false);
  };

  window?.ethereum?.on("accountsChanged", function (accounts) {
    connectMetamsk();
  });

  window?.ethereum?.on("chainChanged", (_chainId) => setnetworkChainId(_chainId));


  useEffect(() => {
    connectMetamsk();
    connectContract();
  }, [networkChainId]);


  const { pathname } = useLocation();


  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  const classes = `header-section ${sticky}`;

  let simpleHeader = false;
  if (
    pathname === "/register" ||
    pathname === "/login" ||
    pathname === "/forgotpassword"
  ) {

    simpleHeader = true;
  }

  return (
    <>
      {simpleHeader ? <SimpleHeader /> : (<div className={classes}>
        <div className="relative bg-black headermains">
          <div className="container mx-auto full_Container">
            <div className="navbar-tabs main-menu">
              <div className="logo-section">
                <Link to="/">
                  <img
                    className="h-16 w-auto sm:h-16"
                    src={`${REACT_APP_WEBSITE_URL}/assets/images/Logo-header.png`}
                    alt=""
                  />
                </Link>
              </div>
              <div className="mobileHamburger lg:hidden 2xl:order-2 xl:order-2 lg:order-2 md:order-2 sm:order-1 order-1">
                <button
                  // onClick={handleNavbarModal}
                  onMouseOver={handleNavbarModal}
                  type="button"
                  className="nav-toggle bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open menu</span>
                  {/* Heroicon name: outline/menu */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <ul className="ul-menu desktopUl_Menu">
                <li>
                  <Link
                    to="/marketplace"
                    className="text-base font-medium text-white hover:text-white-900"
                  >
                    {" "}
                    <span translate="no"> Marketplace </span> {" "}
                  </Link>
                </li>
                <li className="submenu-dropdown">
                  <a

                    className="text-base font-medium text-white hover:text-white-900"
                  >
                    Team <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="submenu-ul">
                    <li className="About">
                      <Link
                        className="text-base font-medium text-black hover:text-white-900 p-2 block w-full border-b"
                        to="/team"
                      >
                        About us
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/selltoken"
                    className="text-base font-medium text-white hover:text-white-900"
                  >
                    {" "}
                    Sell <span translate="no"> Token </span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/learn"
                    className="text-base font-medium text-white hover:text-white-900"
                  >
                    {" "}
                    Learn{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    className="text-base font-medium text-white hover:text-white-900"
                  >
                    {" "}
                    <span translate="no"> FAQ </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className="text-base font-medium text-white hover:text-white-900"
                  >
                    {" "}
                    Blogs
                  </Link>
                </li>

                {isConnected ? (
                  <>
                    <button
                      value="submit"
                      className="ring-green-400 ring-1 rounded text-white bg-green-500 px-3 py-[10px] hover:bg-green-600 text-md"
                      onClick={() => disConnectMetamsk()}
                    >
                      Disconnect
                    </button>
                    <button className="bg-black text-white font-semibold py-2 px-4 rounded inline-flex items-center text-xs">
                      {accountAddress.substring(0, 5) +
                        "....." +
                        accountAddress.substring(accountAddress.length - 5)}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="Menu-translator md:flex space-x-10 2xl:order-3 xl:order-3 lg:order-3 md:order-3 sm:order-3 order-3 mobilehde">
                      <div id="google_translate_element"></div>
                    </div>
                    <button
                      value="submit"
                      className=" ring-1 connectBtn text-white"
                      onClick={() => connectMetamsk()}
                    >
                      Connect to <span translate="no"> Wallet </span>
                    </button>
                  </>
                )}
                <select
                  className=" ring-1 EthermBtn"
                  onChange={(e) => ChangeNetwork(e)}
                >
                  <option value={REACT_APP_ETHEREUM_NETWORK_ID}><span translate="no">Ethereum</span> </option>
                  <option value={REACT_APP_POLYGON_NETWORK_ID} {...isPolygonChain ? "selected" : ""} >
                    <span translate="no">Polygon</span>

                  </option>
                </select>

                <div className="user-menu">

                  {parseInt(user?.role) === 2 || parseInt(user?.rollId) === 2 ? (
                    <>
                      <div className="dropdown inline-block relative">
                        <button className="bg-black text-white font-semibold py-2 px-4 rounded inline-flex items-center accountDropBTN">
                          <span className="mr-1">Account</span>
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                          </svg>
                        </button>
                        <ul className="dropdown-menu absolute hidden text-gray-700 pt-1 bg-black w-48 z-50 right-0">
                          <li className="">
                            <Link
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              to="/myaccount"
                            >
                              {user?.firstName}
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              to="/editaccount"
                            >
                              Edit Account
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              to="/idverification"
                            >
                              Identify Verification
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              to="/portfolio"
                            >
                              Portfolio
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              to="/orders"
                            >
                              Orders
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              to="/documents"
                            >
                              Documents
                            </Link>
                          </li>
                          <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/rejectedOrders"
                                >
                                  Rejected Orders
                                </Link>
                              </li>
                          <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/claimRent"
                                >
                                  Claim Rent
                                </Link>
                              </li>
                          <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/sellTokenHistory"
                                >
                                  Sell Token History
                                </Link>
                              </li>
                              
                              
                          <li className="">
                            <a
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              href="#"
                              onClick={(e) => handleLogout(e)}
                            >
                              Sign out
                            </a>
                          </li>
                        </ul>
                      </div>
                      {(parseInt(user?.role) === 2 || parseInt(user?.rollId) === 2) && (
                        <Link
                          style={{
                            color: "red",
                            position: "relative",
                            marginRight: "10px",
                          }}
                          to={"/cart"}
                        >
                          {" "}
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>{" "}
                          <span className="quantity-val">{cartQuantity}</span>{" "}
                        </Link>
                      )}
                    </>
                  ) : parseInt(user?.role) === 1 || parseInt(user?.rollId) === 1 ? (
                    <>
                      {" "}
                      <div className="dropdown inline-block relative">
                        <button className="bg-black text-white font-semibold py-2 px-4 rounded inline-flex items-center">
                          <span className="mr-1">Account</span>
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                          </svg>
                        </button>
                        <ul className="dropdown-menu absolute hidden text-gray-700 pt-1 bg-black w-48 z-50 right-0">
                          <li className="">
                            <Link
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              to="/myaccount"
                            >
                              {user?.firstName}
                            </Link>
                          </li>

                          <li className="">
                            <a
                              className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                              href="/"
                              onClick={(e) => handleLogout(e)}
                            >
                              Sign out
                            </a>
                          </li>
                        </ul>
                      </div>{" "}
                    </>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        {" "}
                        <span translate="no" style={{ paddingRight: "10px" }}>
                          {" "}
                          Login {" "}
                        </span>{" "}
                      </Link>
                      <Link
                        to="/register"
                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        <span translate="no" style={{ paddingLeft: "10px" }}>
                          {" "}
                          Register{" "}
                        </span>{" "}
                      </Link>
                    </>
                  )}
                </div>
              </ul>

            </div>
          </div>
          <div
            id="Monile-menu"
            style={{ display: navbar ? "block" : "none" }}
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <Link to="/">
                    <img
                      className="h-8 w-auto sm:h-16"
                      src="assets/images/logo-footer.png"
                      alt=""
                    />
                  </Link>
                  <div className="-mr-2">
                    <button
                      id="hide-drop"
                      type="button"
                      onClick={handleNavbarModal}
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <ul className="ul-menu">
                    <li>
                      <Link
                        to="/marketplace"
                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        {" "}
                        <span translate="no"> Marketplace </span>{" "}
                      </Link>
                    </li>
                    <li className="submenu-dropdown">
                      <a

                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        Team <i className="fa fa-angle-down"></i>
                      </a>
                      <ul className="submenu-ul">
                        <li>
                          <Link
                            className="text-base font-medium text-black hover:text-white-900 p-2 block w-full border-b"
                            to="/team"
                          >
                            About us
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        to="/selltoken"
                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        {" "}
                        Sell <span translate="no"> Token </span>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/learn"
                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        {" "}
                        Learn{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/faq"
                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        {" "}
                        <span translate="no"> FAQ </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        className="text-base font-medium text-white hover:text-white-900"
                      >
                        {" "}
                        Blogs
                      </Link>
                    </li>

                    {isConnected ? (
                      <>
                        <button
                          value="submit"
                          className="ring-green-400 ring-1 rounded text-white bg-green-500 px-3 py-[10px] hover:bg-green-600 text-md"
                          onClick={() => disConnectMetamsk()}
                        >
                          Disconnect
                        </button>
                        <button className="bg-black text-white font-semibold py-2 px-4 rounded inline-flex items-center text-xs">
                          {accountAddress.substring(0, 5) +
                            "....." +
                            accountAddress.substring(accountAddress.length - 5)}
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="Menu-translator md:flex space-x-10 2xl:order-3 xl:order-3 lg:order-3 md:order-3 sm:order-3 order-3 mt-2 d-block">
                          <div id="google_translate_element"></div>
                        </div>
                        <button
                          value="submit"
                          className=" ring-1 connectBtn text-white mobileconnt"
                          onClick={() => connectMetamsk()}
                        >
                          Connect to <span translate="no"> Wallet </span>
                        </button>
                      </>
                    )}
                    <select
                      className=" ring-1 EthermBtn"
                      onChange={(e) => ChangeNetwork(e)}
                    >
                      <option value={REACT_APP_ETHEREUM_NETWORK_ID}><span translate="no">Ethereum</span>  </option>
                      <option value={REACT_APP_POLYGON_NETWORK_ID} {...isPolygonChain ? ("selected") : ""}>
                        <span translate="no">Polygon</span>
                      </option>
                    </select>

                    <div className="user-menu">
                      {(parseInt(user?.role) === 2 || parseInt(user?.rollId) === 2) && (
                        <Link
                          style={{
                            color: "red",
                            position: "relative",
                            marginRight: "10px",
                          }}
                          to={"/cart"}
                        >
                          {" "}
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>{" "}
                          <span className="quantity-val">{cartQuantity}</span>{" "}
                        </Link>
                      )}
                      {parseInt(user?.role) === 2 || parseInt(user?.rollId) === 2 ? (
                        <>
                          <div className="dropdown inline-block relative">
                            <button className="bg-black text-white font-semibold py-2 px-4 rounded inline-flex items-center">
                              <span className="mr-1">Account</span>
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                              </svg>
                            </button>
                            <ul className="dropdown-menu absolute hidden text-gray-700 pt-1 bg-black w-48 z-50 right-0">
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/myaccount"
                                >
                                  {user?.firstName}
                                </Link>
                              </li>
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/editaccount"
                                >
                                  Edit Account
                                </Link>
                              </li>
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/idverification"
                                >
                                  Identify Verification
                                </Link>
                              </li>
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/portfolio"
                                >
                                  Portfolio
                                </Link>
                              </li>
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/orders"
                                >
                                  Orders
                                </Link>
                              </li>
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/documents"
                                >
                                  Documents
                                </Link>
                              </li>
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/rejectedOrders"
                                >
                                  Rejected Orders
                                </Link>
                              </li>
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/claimRent"
                                >
                                  Claim Rent
                                </Link>
                              </li>
                              
                              <li className="">
                                <a
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  href="/"
                                  onClick={(e) => handleLogout(e)}
                                >
                                  Sign out
                                </a>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : parseInt(user?.role) === 1 || parseInt(user?.rollId) === 1 ? (
                        <>
                          {" "}
                          <div className="dropdown inline-block relative">
                            <button className="bg-black text-white font-semibold py-2 px-4 rounded inline-flex items-center">
                              <span className="mr-1">Account</span>
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                              </svg>
                            </button>
                            <ul className="dropdown-menu absolute hidden text-gray-700 pt-1 bg-black w-48 z-50 right-0">
                              <li className="">
                                <Link
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  to="/myaccount"
                                >
                                  {user?.firstName}
                                </Link>
                              </li>

                              <li className="">
                                <a
                                  className="rounded-t hover:text-gray-100 py-2 px-4 block whitespace-no-wrap text-gray-100"
                                  href="/"
                                  onClick={(e) => handleLogout(e)}
                                >
                                  Sign out
                                </a>
                              </li>
                            </ul>
                          </div>{" "}
                        </>
                      ) : (
                        <>
                          <Link
                            to="/login"
                            className="text-base font-medium text-white hover:text-white-900"
                          >
                            {" "}
                            <span translate="no" style={{ paddingRight: "10px" }}>
                              {" "}
                              Login mobile{" "}
                            </span>{" "}
                          </Link>
                          <Link
                            to="/register"
                            className="text-base font-medium text-white hover:text-white-900"
                          >
                            <span translate="no" style={{ paddingLeft: "10px" }}>
                              {" "}
                              Register-2{" "}
                            </span>{" "}
                          </Link>
                        </>
                      )}
                    </div>
                  </ul>

                  <nav className="grid gap-y-8 d-none">
                    <Link
                      to="/marketplace"
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {" "}
                        <span translate="no"> Marketplace </span>{" "}
                      </span>
                    </Link>

                    <Link
                      to="/selltoken"
                      className="ml-3 text-base font-medium text-gray-900"
                    >
                      {" "}
                      Sell <span translate="no">Token</span>{" "}
                    </Link>

                    <Link
                      to="/team"
                      className="ml-3 text-base font-medium text-gray-900"
                    >
                      {" "}
                      Team{" "}
                    </Link>

                    <Link
                      to="/learn"
                      className="ml-3 text-base font-medium text-gray-900"
                    >
                      {" "}
                      Learn{" "}
                    </Link>
                 
                  <Link
                    to="/blog"
                    className="ml-3 text-base font-medium text-gray-900"
                  >
                    {" "}
                    Blogs
                  </Link>
            
                    <Link
                      to="/faq"
                      className="ml-3 text-base font-medium text-gray-900"
                    >
                      {" "}
                      <span translate="no"> FAQ </span>{" "}
                    </Link>

                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)}

    </>
  );
};

export default MainHeader; 
