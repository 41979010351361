import axios from "axios";

import * as endpoints from "../constants/Endpoints";
import Swal from "sweetalert2";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}api/`,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  return req;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      Swal.fire({
        title:
          "Session has been expired due to inactivity, please login again!",
        showCancelButton: true,
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          window.location = "/login";
        }
      });

      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export const signIn = (formData) => API.post(endpoints.LOGIN, formData);
export const signUp = (formData) => API.post(endpoints.USERS, formData);
export const ForgotPassword = (formData) =>
  API.post(endpoints.FORGET_PASSWORD, formData);
export const getSubscribers = () => API.get(endpoints.SUBSCRIBERS);
export const deleteSubscriber = (id) =>
  API.delete(`${endpoints.SUBSCRIBERS}/${id}`);
export const verifyMail = (token) =>
  API.post(`${endpoints.VERIFY_EMAIL}/${token}`);

// Users
export const me = () => API.get(endpoints.ME);
export const IdUser = (formData) => API.patch(endpoints.USERS, formData);
export const getUsers = () => API.get(endpoints.USERS);
export const getUser = (id) => API.get(`${endpoints.USERS}/${id}`);
export const deleteUser = (id) => API.delete(`${endpoints.USERS}/${id}`);
export const updateUser = (formData, id) =>
  API.put(`${endpoints.USERS}/${id}`, formData);
export const editAccount = (formData) =>
  API.patch(endpoints.CHANGE_PASSWORD, formData);
export const resetPassword = (formData, id, token) =>
  API.post(`${endpoints.RESET_PASSWORD}/${id}/${token}`, formData);

// Property
export const createProperty = (formData) =>
  API.post(endpoints.PROPERTY, formData);

export const getProperties = () => API.get(endpoints.PROPERTY);
export const activeProperties = () => API.get(endpoints.ACTIVEPROPERTIES);
export const investorDetails = () => API.get(endpoints.INVESTORDETAILS);

export const getProperty = (id) => API.get(`${endpoints.PROPERTY}/${id}`);
export const getPropertyData = (id) =>
  API.get(`${endpoints.GETPROPERTYDATA}/${id}`);
export const updateProperty = (formData, id) => {
  API.patch(`${endpoints.PROPERTY}/${id}`, formData);
};
export const deleteProperty = (id) => API.delete(`${endpoints.PROPERTY}/${id}`);
export const deletePropertyImages = (id) =>
  API.delete(`${endpoints.DELETEIMAGES}/${id}`);

export const getPropertiesByStatus = () =>
  API.get(endpoints.PROPERTY_BY_STATUS);
export const updatePropertyStatus = (formData, id) =>
  API.patch(`${endpoints.PROPERTY_STATUS}/${id}`, formData);
export const getUserProperty = (formData, id) =>
  API.patch(`${endpoints.GERUSERPROPERTY}/${id}`, formData);
export const updateSendPropertyToken = (formData, id) =>
  API.patch(`${endpoints.UPDATESENDPROPERTYTOKEN}/${id}`, formData);

export const inStock = (id) => API.get(`${endpoints.INSTOCK}/${id}`);

// Team
export const createTeam = (formData) => API.post(endpoints.TEAM, formData);
export const getTeams = () => API.get(endpoints.TEAM);
export const team = (id) => API.get(`${endpoints.TEAM}/${id}`);
export const deleteTeam = (id) => API.delete(`${endpoints.TEAM}/${id}`);
export const updateTeam = (updatedTeamData, id) =>
  API.patch(`${endpoints.TEAM}/${id}`, updatedTeamData);

// Learn
export const getLearn = (id) => API.get(`${endpoints.LEARN}/${id}`);
export const createLearn = (formData) => API.post(endpoints.LEARN, formData);
export const fetchLearn = () => API.get(endpoints.LEARN);
export const deleteLearn = (id) => API.delete(`${endpoints.LEARN}/${id}`);
export const editLearn = (updatedBlogsdata, id) =>
  API.put(`${endpoints.LEARN}/${id}`, updatedBlogsdata);

// Blogs
export const createBlogs = (formData) => API.post(endpoints.BLOGS, formData);
export const getBlogs = () => API.get(endpoints.BLOGS);

export const deleteBlogs = (id) => API.delete(`${endpoints.BLOGS}/${id}`);
export const updateBlogs = (updatedBlogsdata, id) =>
  API.put(`${endpoints.BLOGS}/${id}`, updatedBlogsdata);
export const getBlogsById = (blogsId) =>
  API.get(`${endpoints.BLOGS}/${blogsId}`);

// FAQ
export const createFaq = (formData) => API.post(endpoints.FAQ, formData);
export const fetchFaq = () => API.get(endpoints.FAQ);
export const deltedFaq = (id) => API.delete(`${endpoints.FAQ}/${id}`);
export const editFaq = (updatedBlogsdata, id) =>
  API.put(`${endpoints.FAQ}/${id}`, updatedBlogsdata);
export const getFaqById = (faqId) => API.get(`${endpoints.FAQ}/${faqId}`);
export const getFaq = (faqId) => API.get(`${endpoints.FAQ_ADMIN}/${faqId}`);

// Email
export const createEmail = (formData) => API.post(endpoints.EMAIL, formData);
export const fetchEmail = () => API.get(endpoints.EMAIL);
export const deltedEmail = (id) => API.delete(`${endpoints.EMAIL}/${id}`);
export const editEmail = (updatedBlogsdata, id) =>
  API.put(`${endpoints.EMAIL}/${id}`, updatedBlogsdata);
export const getEmailById = (emailId) =>
  API.get(`${endpoints.EMAIL}/${emailId}`);

// Fee
export const createFee = (formData) => API.post(endpoints.FEE, formData);
export const getMyFee = (formData) => API.post(endpoints.GET_MY_FEES, formData);
export const fetchFee = () => API.get(endpoints.FEE);
export const getFee = () => API.get(endpoints.GET_MY_FEES);
// export const getMyFee = () => API.get(endpoints.GET_MY_FEES);
export const editFee = (formData, id) =>
  API.put(`${endpoints.FEE}/${id}`, formData);

// Carts
export const getCarts = () => API.get(endpoints.CART);
export const createCart = (formData) => API.post(endpoints.CART, formData);
export const deleteCart = (id) => API.delete(`${endpoints.CART}/${id}`);
export const updatedCartQuantity = (cartquantity, id) => {
  return API.put(`${endpoints.CART}/${id}`, { cartquantity: cartquantity });
};

// Orders
export const createOrder = (formData) => API.post(endpoints.ORDER, formData);
export const getOrders = () => API.get(endpoints.ORDER); //for user portfolio
export const getOrder = (id) => API.get(`${endpoints.ORDER}/${id}`);
export const fetchOrderByAdmin = () => API.get(endpoints.ORDERFETCHBYADMIN);
export const fetchRentListing = () => API.get(endpoints.ORDERRENTDISTRIBUTION);
export const fetchCompletedRent = () => API.get(endpoints.ORDERRENTCOMPLETED);
export const orderDeletedByAdmin = (id) => API.delete(`/order/admin/${id}`);
export const updateOrderStatus = (id, formData) =>
  API.patch(`${endpoints.UPDATE_ORDER_STATUS}/${id}`, formData);
export const rejectOrder = (id, formData) =>
  API.patch(`${endpoints.UPDATE_REJECT_ORDER}/${id}`, formData);
export const refundOrderPayment = (id, formData) =>
  API.patch(`${endpoints.UPDATE_ORDER_REFUND_PAYMENT}/${id}`, formData);
export const getUserRejectedOrders = () =>API.get(endpoints.GET_USER_REJECTED_ORDERS);

// export const updateRentDistribution = (formData, id) =>
//   API.patch(`${endpoints.PROPERTY_STATUS}/${id}`, formData);

export const updateRentDistribution = (formData, id) =>
  API.patch(`${endpoints.UPDATE_MONTHLY_RENT}/${id}`, formData);
export const updateClaimedRentDistribution = (formData, id) =>
  API.patch(`${endpoints.UPDATE_CLAIMED_RENT}/${id}`, formData);

export const createCheckout = (formData) =>
  API.post(endpoints.CHECKOUTORDER, formData);

// Social
export const createSocial = (formData) => API.post(endpoints.SOCIAL, formData);
export const fetchSocial = () => API.get(endpoints.SOCIAL);
export const getSocialById = (id) => API.get(`${endpoints.SOCIAL}/${id}`);
export const deltedSocial = (id) => API.delete(`${endpoints.SOCIAL}/${id}`);
export const editSocial = (formData, id) =>
  API.put(`${endpoints.SOCIAL}/${id}`, formData);

//Terms and condition

export const createTerm = (formData) => API.post(endpoints.TERM, formData);
export const fetchTerm = () => API.get(endpoints.TERM);
export const fetchTermById = () => API.get(`${endpoints.TERM}`);
export const editTerm = (formData, id) =>
  API.put(`${endpoints.TERM}/${id}`, formData);

////
export const sendUserUpdate = (formData) =>
  API.post(endpoints.SEND_USER_UPDATE, formData);

export const propertiesSoldStatus = (formData, id) =>
  API.patch(`${endpoints.PROPERTY_SOLD}/${id}`, formData);
export const deployContract = (formData, id) =>
  API.patch(`${endpoints.PROPERTY_DEPLOY}/${id}`, formData);

//Dashboard
export const propertiesCount = () => API.get(endpoints.PROPERTIESCOUNT);
export const totalTokens = () => API.get(endpoints.TOTALTOKEN);
export const totalPrice = () => API.get(endpoints.TOTALPRICE);
export const yearlyGrossRent = () => API.get(endpoints.YEARLYGROSSRENT);
export const monthlyGrossRent = () => API.get(endpoints.MONTHLYGROSSRENT);
export const yearlyNetRent = () => API.get(endpoints.YEARLYNETRENT);
export const monthlyNetRent = () => API.get(endpoints.MONTHLYNETRENT);
export const totalInvestor = () => API.get(endpoints.TOTALINVESTOR);

//Sell Token
export const getUserTokenAddress = (id) =>
  API.get(`${endpoints.GETUSERPROPERTYADDRESS}/${id}`);
export const getUserProperyDetail = (formData) =>
  API.post(endpoints.GETUSERPROPERYDETAIL, formData);
export const updateSellToken = (formData) =>
  API.post(endpoints.UPDATESELLTOKEN, formData);
export const getSellToken = () => API.get(endpoints.GETSELLTOKEN);
export const updateSellTokenStatus = (id, formData) =>
  API.patch(`${endpoints.UPDATESELLTOKENSTATUS}/${id}`, formData);
export const updateTransferredUSDC = (id, formData) =>
  API.patch(`${endpoints.UPDATETRANSFERREDUSDC}/${id}`, formData);
export const getUserSellTokens = () =>
  API.get(`${endpoints.GET_USERS_SELL_TOKENS}`);

// Monthly Rent  
export const getDistributedRent = (id) =>
  API.get(`${endpoints.DISTURBEDRENT}/${id}`);

export const getDistributedRentdetails = () =>
  API.get(`${endpoints.GETRENTDISTRIBUTEDDETAILS}`);
  
export const getClaimedRent = () =>
  API.get(`${endpoints.GET_CLAIMED_RENT}`);

export const GetUserPendingRentDistribution = (userId) =>
  API.get(`${endpoints.GETUSERPENDINGRENTDISTRIBUTION}/${userId}`);

export const GetClaimedRentDetails = (userId,formData) =>
  API.post(`${endpoints.GET_CLAIMED_RENT_DETAILS}/${userId}`,formData);

export const GetUserPendingRent = (userId) =>
  API.get(`${endpoints.GET_USER_PENDING_RENT}/${userId}`);

export const getPendingRent = () =>
  API.get(`${endpoints.GET_PENDING_RENT}`);

export const claimUserRent = (formData) =>
  API.post(endpoints.CLAIM_USER_RENT,formData);

export const getGeneratedRentEmails = () =>
  API.get(endpoints.GET_GENERATED_RENT_EMAILS);


// Invoice
export const createInvoice = (formData) =>
  API.post(endpoints.CREATEINVOICE, formData);

export const createContractSign = (formData) =>
  API.post(endpoints.CREATECONTRACTSIGN, formData);

// Agrements
export const pendingAggrement = () =>
  API.get(endpoints.GETUSERPENDINGAGGREMENT);
export const createAgreemetPdf = (formData) =>
  API.post(endpoints.CREATEAGREEMENTPDF, formData);
export const updateAgreementPdf = (formData) =>
  API.post(endpoints.UPDATEAGREEMENTPDF, formData);
export const getOrderDetails = (id) =>
  API.get(`${endpoints.ORDER_DETAILS}/${id}`);
export const genratePropertyReport = (formData) =>
  API.post(endpoints.GENRATE_PROPERTY_REPORT, formData);
export const genrateOrderReport = (formData) =>
  API.post(endpoints.GENRATE_ORDER_REPORT, formData);
export const genrateRentReport = (formData) =>
  API.post(endpoints.GENRATE_RENT_REPORT, formData);
export const genrateSaleTokenReport = (formData) =>
  API.post(endpoints.GENRATE_SALE_TOKEN_REPORT, formData);
export const genrateUsersReport = (formData) =>
  API.post(endpoints.GENRATE_USERS_REPORT, formData);
export const getPropertyEmail = () =>
  API.get(endpoints.GET_PROPERTY_EMAIL);

// Countries  

export const getAllCountry = () =>
  API.get(endpoints.GET_ALL_COUNTRY);

// export const getUserTokenAddress = (formData, id) => API.patch(`${endpoints.PROPERTY_DEPLOY}/${id}`, formData);
