import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { formatPercentage, formatNumber } from "../utils/Formatter";
import { createInvoice } from "../redux/api";
import React, { useState } from "react";

const { REACT_APP_BASE_API_URL } = process.env;

const PendingAggrement = ({ item }) => {

  const [isProcessingPdf, setisProcessingPdf] = useState(false);

  const createInoice = (orderId) => {
    setisProcessingPdf(true);
    let userInfo = JSON.parse(localStorage.getItem("profile"));
    const Id = userInfo["id"];
    let formData = {
      orderId: orderId,
      id: Id,
    };

    createInvoice(formData)
      .then((res) => {
        setisProcessingPdf(false);
        if (res?.data?.data) {
          window.open(`${REACT_APP_BASE_API_URL}${res.data.data}`, "_blank");
        }
      })
      .catch((error) => {
        console.log(error);
        setisProcessingPdf(false);
        return toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <tr className="bg-white">
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          <Link
            className="product-id text-blue-900 underline"
            to={`/orderdetails/${item?.id}`}
          >
            #{item?.id}
          </Link>
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {moment(item?.createdAt).format("MMMM Do YYYY")}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {formatNumber(item?.propertyToken * 1)}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.order.paymentStatus}
        </td>
        <td className="border text-center sm:text-sm= xl:text:md text-md p-3">
          {item?.isAgreementSigned ? (
            <>
              <a
                className="rounded-full bg-red-900 text-white view px-8 py-2 BTNBox"
                href={REACT_APP_BASE_API_URL + item?.agreementPdf}
                target="_blank"
              >
                View Agreement
              </a>
            </>
          ) : (
            <>
              {" "}
              <Link
                to={`/PendingSignatureDetails/${item?.id}`}
                className="rounded-full bg-blue-900 text-white view px-8 py-2"
              >
                Sign Agreement
              </Link>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default PendingAggrement;
