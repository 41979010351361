import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { register } from "../redux/features/authSlice";
import { Spinner } from "../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TERMSCONDITION } from "../constants/Messages";
import { getAllCountry } from "../redux/api";

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    password: yup
      .string()
      .typeError("Must be a number")
      .required("Password is Required")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$",
        "Must Contain 8 Characters,One Uppercase,One Lowercase, One Number,One special Character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords does not match"),
    country: yup.string().required("Required"),
    phoneCode: yup.string().required("Required"),
    
    phone: yup
      .number()
      .required("Required")
      .typeError("Must be a number")

    // .test(
    //   "maxLength",
    //   "Must  have 10 Numbers",
    //   (val) => !isNaN(val) && `${val}`.length === 10
    // ),
  })
  .required();

const Register = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  let { loading, error } = useSelector((state) => ({ ...state.auth }));
  const [emptyFnameMessage, setEmptyFnameMessage] = useState("");
  const [emptyLastNameMessage, setEmptyLastNameMessage] = useState("");
  const [phoneCodeList, setphoneCodeList] = useState("");
  const {
    register: _register,
    formState: { errors },
    handleSubmit: _handleSubmitData,
  } = useForm({ resolver: yupResolver(schema) }); 

  useEffect(() => {
    getAllCountry().then((res)=>{
      let phoneCodeList = res.data.allCountry.map(({phoneCode, iso3}) => <option value={phoneCode}> {iso3} +{phoneCode} </option>)
      setphoneCodeList(phoneCodeList);
    });
  }, []);
 
  

  const handleChange = (event) => {
    if (event.target.checked) {
    } else {
    }
    setIsSubscribed((current) => !current);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);
  const handleSubmit = (data) => {

    var fname = data.firstName;
    if (fname.match(/^\s*$/) !== null) {
      setEmptyFnameMessage("Please enter first name");
      return;
    }
    else {
      setEmptyFnameMessage("");
    }

    var lname = data.lastName;
    if (lname.match(/^\s*$/) !== null) {
      setEmptyLastNameMessage("Please enter last name");
      return;
    }
    else {
      setEmptyLastNameMessage("");
    }


    if (!isSubscribed) {
      return toast.error(TERMSCONDITION);
    }
    dispatch(register({ data, navigate, toast }));
  };
  return (
    <>
      <section id="register-form">
        <div className="w-full flex flex-wrap">
          {/* Register Section */}
          <div className="w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-center bg-gray-100">
            <div className="centered-div">
              <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-2 lg:px-24">
                <h2 className="text-center text-4xl font-bold text-blue-800 mb-5">
                  Join<span className="font-normal ml-2">Us</span>
                </h2>
                <form
                  className="flex flex-col mt-5"
                  onSubmit={_handleSubmitData(handleSubmit)}
                >
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          First Name <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          {..._register("firstName")}
                          placeholder="John"
                          className="appearance-none w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        <div style={{ color: "red" }}>{emptyFnameMessage}</div>
                        <p className="text-sm text-red">
                          {errors.firstName?.message}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Last Name <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          {..._register("lastName")}
                          placeholder="Smith"
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        <div style={{ color: "red" }}>{emptyLastNameMessage}</div>
                        <p className="text-sm text-red">
                          {errors.lastName?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col pt-4">
                    <label htmlFor="email" className="text-lg font-normal">
                      Email <span className="text-red">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      {..._register("email")}
                      placeholder="your@email.com"
                      className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                    />
                    <p className="text-sm text-red">{errors.email?.message}</p>
                  </div>
                  <div className="grid grid-cols-6 lg:gap-4 xl:gap-4 md:gap-4">
                    <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label
                          htmlFor="password"
                          className="text-lg font-normal"
                        >
                          Password <span className="text-red">*</span>
                        </label>
                        <input
                          type="password"
                          name="password"
                          {..._register("password")}
                          placeholder="Password"
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        <p className="text-sm text-red">
                          {errors.password?.message}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label
                          htmlFor="confirm-password"
                          className="text-lg font-normal"
                        >
                          Confirm Password <span className="text-red">*</span>
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          {..._register("confirmPassword")}
                          placeholder="Password"
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        <p className="text-sm text-red">
                          {errors.confirmPassword?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-3 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label
                          htmlFor="country"
                          className="text-lg font-normal"
                        >
                          Country <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          name="country"
                          {..._register("country")}
                          placeholder="Country"
                          className="appearance-none  rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        <p className="text-sm text-red">
                          {errors.country?.message}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label
                          htmlFor="postal-code"
                          className="text-lg font-normal"
                        >
                          Postal Code
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          {..._register("postalCode")}
                          placeholder="Postal Code"
                          className="appearance-none  rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        <p className="text-sm text-red">
                          {errors.postalCode?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                 
                  <div className="flex flex-col pt-4">
                    <label htmlFor="phoneCode" className="text-lg font-normal">
                      Mobile Number <span className="text-red">*</span>
                    </label>
                   
                    <div style={{ display: "flex" }}>
                      <select name="phoneCode" id="phoneCode" {..._register("phoneCode")} className="appearance-none py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10" style={{ width: "20%" }}>
                        <option value=""> Select Code</option>
                        {phoneCodeList}
                      </select> &nbsp;
                      <input
                        type="text"
                        name="phone"
                        placeholder="Mobile Number"
                        {..._register("phone")}
                        className="appearance-none w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                      />
                    </div>
                    <p className="text-sm text-red">{errors.phoneCode?.message} {" "}{errors.phone?.message}</p>
                  </div>
                  <br></br>
                  <p className="term_pra">
                    <input
                      type="checkbox"
                      id="tc"
                      value={isSubscribed}
                      onChange={handleChange}
                      name="subscribe"
                    />{" "}
                    I accept the
                    <Link
                      to="/termscondition"
                      style={{ textDecoration: "underline" }}
                    >
                      {"    "} {"    "}
                      Terms Condition &amp; Privacy Policy
                    </Link>
                    {"    "} {"    "}
                  </p>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <input
                      type="submit"
                      value="Submit"
                      defaultValue="Register"
                      className="bg-blue-800 text-white font-bold text-lg hover:bg-blue-500 p-2 mt-8 max-w-xs rounded-full m-auto w-full"
                    />
                  )}
                </form>
                <div className="text-center pt-8">
                  <p>
                    Already have an account?{"  "}
                    {"  "}
                    <Link
                      to="/login"
                      className="underline font-semibold text-blue-800"
                      style={{ marginLeft: ".5rem" }}
                    >
                      Log in here.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Image Section */}
          <div className="lg:w-1/2 xl:w-1/2 shadow-2xl relative bg-black">
            <img
              className="object-cover w-full min-h-screen hidden lg:block xl:block  h-full"
              src="assets/images/bg.jpg"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
